import React from "react";
import { Link } from "react-router-dom";
// const calculators = [
//   { name: "SIP", description: "Calculate how much you need to save or how much you will accumulate with your SIP", link: "/sip-calculator", img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/sip.81144080.png" },
//   { name: "PPF", description: "Calculate your returns on Public Provident Fund (PPF)", link: "/ppf-calculator", img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/swp.e6a90ff4.png" },
//   { name: "EPF", description: "Calculate returns for your Employee’s Provident Fund (EPF)", link: "/epf-calculator", img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/lumpsum.1794f9ea.png" },
//   { name: "Lumpsum", description: "Calculate returns for lumpsum investments to achieve your financial goals", link: "/lumpsum-calculator", img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/ssy.519e79bf.png" },
//   { name: "FD", description: "Calculate your final amount with Systematic Withdrawal Plans (SWP)", link: "/fd-calculator", img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/swp.e6a90ff4.png" },
//   { name: "MF", description: "Calculate the returns on your mutual fund investments", link: "/mutual-fund-returns-calculator", img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/mutualFund.30d93762.png" },
//   { name: "SWP", description: "Calculate your final amount with Systematic Withdrawal Plans (SWP)", link: "/swp-calculator", img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/fixedDeposit.5c66d7e6.png" },
// ];
const calculators = [
  {
    name: "SIP",
    description: "Calculate how much you need to save or how much you will accumulate with a Systematic Investment Plan (SIP).",
    link: "/sip-calculator",
    img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/sip.81144080.png"
  },
  {
    name: "PPF",
    description: "Estimate the maturity amount and returns on your Public Provident Fund (PPF) investments.",
    link: "/ppf-calculator",
    img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/ssy.519e79bf.png"
  },
  {
    name: "EPF",
    description: "Calculate the interest and final corpus of your Employee’s Provident Fund (EPF) savings.",
    link: "/epf-calculator",
    img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/lumpsum.1794f9ea.png"
  },
  {
    name: "Lumpsum",
    description: "Determine the future value of a one-time investment and plan your financial goals effectively.",
    link: "/lumpsum-calculator",
    img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/swp.e6a90ff4.png"
  },
  {
    name: "FD",
    description: "Calculate the maturity amount and interest earned on your Fixed Deposit (FD) investment.",
    link: "/fd-calculator",
    img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/fixedDeposit.5c66d7e6.png"
  },
  {
    name: "MF",
    description: "Compute potential returns on your mutual fund investments based on different growth rates.",
    link: "/mutual-fund-calculator",
    img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/mutualFund.30d93762.png"
  },
  {
    name: "SWP",
    description: "Estimate the amount you can withdraw periodically from your investments while maintaining stability.",
    link: "/swp-calculator",
    img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/swp.e6a90ff4.png"
  },
  {
    name: "RD",
    description: "Check returns on your Recurring Deposit (RD) in just a few clicks.",
    link: "/rd-calculator",
    img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/swp.e6a90ff4.png"
  },
  {
    name: "SSY",
    description: "Calculate returns for Sukanya Smariddhi Yojana (SSY) as per your investment.",
    link: "/ssy-calculator",
    img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/swp.e6a90ff4.png"
  },
  {
    name: "Home Loan",
    description: "Check home loan EMI",
    link: "/home-loan-calculator",
    img: "//assets-netstorage.groww.in/web-assets/billion_groww_desktop/prod/_next/static/media/swp.e6a90ff4.png"
  }
];


const CalculatorsList = () => {
  return (
    <div className="container mx-auto py-6">
      <h1 className="text-3xl font-bold mb-6">Calculators</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {calculators.map((calculator, index) => (
          <Link to={calculator.link} key={index} className="block p-4 border rounded-lg shadow-md hover:shadow-lg transition">
            <img src={calculator.img} alt={calculator.name} className="w-20 h-20 mx-auto" />
            <h2 className="text-xl font-semibold text-center mt-4">{calculator.name}</h2>
            <p className="text-sm text-gray-600 text-center mt-2">{calculator.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CalculatorsList;
