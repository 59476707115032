import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const SWPCalculator = () => {
    const navigate = useNavigate();
    const [investment, setInvestment] = useState(100000);
    const [interestRate, setInterestRate] = useState(6.5);
    const [timePeriod, setTimePeriod] = useState(5);
    const [monthlyWithdrawal, setMonthlyWithdrawal] = useState(5000);
    const [returnRate, setReturnRate] = useState(6);

    const interestRateDecimal = interestRate / 100;
    const maturityAmount = investment * Math.pow(1 + interestRateDecimal / 4, 4 * timePeriod);
    const estReturns = maturityAmount - investment;

    const totalValue = investment + estReturns;

    const chartOptions = {
        chart: {
            type: "pie",
            options3d: { enabled: true, alpha: 45 },
        },
        title: {
            text: "Investment Breakdown",
            align: "center",
            style: { color: "#005555", fontSize: "16px" },
        },
        plotOptions: {
            pie: {
                innerSize: "60%",
                depth: 45,
                dataLabels: { enabled: false },
            },
        },
        legend: {
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "bottom",
            itemStyle: { fontSize: "12px" },
        },
        series: [
            {
                name: "Amount",
                data: [
                    { name: "Invested Amount", y: investment, color: "#EAEAEA" },
                    { name: "Est. Returns", y: estReturns, color: "#005555" },
                ],
                showInLegend: true,
            },
        ],
    };

    return (
        <div className="section-properties container pb-12">
            <div className="w-full flex justify-between items-center">
                <p className="font-bold text-primary text-xl cursor-pointer my-4" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} /> SWP Calculator
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="md:col-span-2 shadow-sm p-6 bg-white rounded-lg border border-gray-300 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2">
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Investment Amount</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">₹</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 px-6"
                                    value={investment}
                                    onChange={(e) => setInvestment(Number(e.target.value))}
                                />
                            </div>
                        </div>

                        <input
                            type="range"
                            className="w-full mb-4"
                            min="10000"
                            max="1000000"
                            value={investment}
                            onChange={(e) => setInvestment(Number(e.target.value))}
                        />


                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Monthly Withdrawal</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">₹</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 px-6"
                                    value={monthlyWithdrawal}
                                    onChange={(e) => setMonthlyWithdrawal(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input
                            type="range"
                            className="w-full mb-4"
                            min="1000"
                            max={investment / 12}
                            value={monthlyWithdrawal}
                            onChange={(e) => setMonthlyWithdrawal(Number(e.target.value))}
                        />
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Expected Return Rate (p.a)</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">%</div>
                              
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 px-6"
                                    value={returnRate}
                                    onChange={(e) => setReturnRate(Number(e.target.value))}
                                    min="1"
                                />
                            </div>
                        </div>
                        <input
                            type="range"
                            className="w-full mb-4"
                            min="1"
                            max="30"
                            value={returnRate}
                            onChange={(e) => setReturnRate(Number(e.target.value))}
                        />

                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Time Period (Years)</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">Yr</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 px-6"
                                    value={timePeriod}
                                    onChange={(e) => setTimePeriod(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input
                            type="range"
                            className="w-full mb-4"
                            min="1"
                            max="50"
                            value={timePeriod}
                            onChange={(e) => setTimePeriod(Number(e.target.value))}
                        />

                        <div className="bg-gray-100 p-4 rounded-md text-gray-800">
                            <p className="flex justify-between text-sm">
                                <span>Invested Amount</span>
                                <span className="text-md font-semibold">₹{investment.toLocaleString()}</span>
                            </p>
                            <p className="flex justify-between text-sm">
                                <span>Est. Returns</span>
                                <span className="text-md font-semibold">₹{estReturns.toLocaleString()}</span>
                            </p>
                            <p className="flex justify-between text-sm mb-0">
                                <span>Total Value</span>
                                <span className="text-md font-semibold">₹{totalValue.toLocaleString()}</span>
                            </p>
                        </div>
                    </div>

                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        <a href="/home">
                            <button className="w-full bg-[#005555] text-white py-2 rounded mt-4">
                                INVEST NOW
                            </button>
                        </a>
                    </div>
                </div>

                <div className="bg-white border border-gray-300 rounded-lg">
                    <div className="text-gray-700 text-base font-semibold p-3">Popular Calculators</div>
                    <div className="flex flex-col divide-y divide-gray-300">
                        {[
                             { name: "SIP Calculator", link: "/sip-calculator" },
                             { name: "PPF Calculator", link: "/ppf-calculator" },
                             { name: "EPF Calculator", link: "/epf-calculator" },
                             { name: "Lumpsum Calculator", link: "/lumpsum-calculator" },
                             { name: "FD Calculator", link: "/fd-calculator" },
                             { name: "SWP Calculator", link: "/swp-calculator" },
                             { name: "Mutual Fund Returns Calculator", link: "/mutual-fund-calculator" },
                             { name: "RD Calculator", link: "/rd-calculator" },
                             { name: "SSY Calculator", link: "/ssy-calculator" },
                             { name: "Home Loan Calculator", link: "/home-loan-calculator" },
                        ].map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className="p-3 text-gray-600 text-sm hover:text-[#005555] hover:bg-[#E5E7EB]"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                </div>

                <div className="md:col-span-2">
                    <h2 className="text-lg font-semibold mb-2">SWP Calculator – Systematic Withdrawal Plan</h2>
                    <p className="text-gray-700">
                        The Systematic Withdrawal Plan (SWP) allows you to withdraw a fixed sum of money periodically from your investment. It is a great option for people looking for regular income from their investments.
                    </p>
                    <h3 className="text-lg font-semibold mb-2">How does SWP work?</h3>
                    <p className="text-gray-700">
                        An SWP enables you to set up regular withdrawals, typically monthly or quarterly, from your mutual fund investments. The amount withdrawn remains constant, and the remaining investment continues to grow at the same rate.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SWPCalculator;
