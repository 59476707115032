import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const LUMPSUMCalculator = () => {
    const navigate = useNavigate();

    const [mode, setMode] = useState("Lumpsum");
    const [investment, setInvestment] = useState(50000);
    const [returnRate, setReturnRate] = useState(10);
    const [timePeriod, setTimePeriod] = useState(15);

    const totalValue = investment * Math.pow(1 + returnRate / 100, timePeriod);
    const estReturns = totalValue - investment;

    const chartOptions = {
        chart: {
            type: "pie",
            options3d: { enabled: true, alpha: 45 },
        },
        title: {
            text: "Investment Breakdown",
            align: "center",
            style: { color: "#005555", fontSize: "16px" },
        },
        plotOptions: {
            pie: {
                innerSize: "60%",
                depth: 45,
                dataLabels: { enabled: false },
            },
        },
        legend: {
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "bottom",
            itemStyle: { fontSize: "12px" },
        },
        series: [
            {
                name: "Amount",
                data: [
                    { name: "Invested Amount", y: investment, color: "#EAEAEA" },
                    { name: "Est. Returns", y: estReturns, color: "#005555" },
                ],
                showInLegend: true,
            },
        ],
    };

    return (
        <div className="section-properties container pb-12">
            <div className="w-full flex justify-between items-center">
                <p
                    className="font-bold text-primary text-xl cursor-pointer my-4"
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} /> Lumpsum Calculator
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

                <div className="md:col-span-2 shadow-sm p-6 bg-white rounded-lg border border-gray-300 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2">
                        {/* <div className="flex space-x-4 mb-4">
                            <button onClick={() => setMode("SIP")}
                                className={`px-4 py-2 rounded ${mode === "SIP" ? "bg-green-100 text-green-800" : "bg-gray-200 text-gray-800"}`}>
                                SIP
                            </button>
                            <button onClick={() => setMode("Lumpsum")}
                                className={`px-4 py-2 rounded ${mode === "Lumpsum" ? "bg-green-100 text-green-800" : "bg-gray-200 text-gray-800"}`}>
                                Lumpsum
                            </button>
                        </div> */}

                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">
                                {mode === "SIP" ? "Monthly Investment" : "Lumpsum Investment"}
                            </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">₹</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 px-6"
                                    value={investment}
                                    onChange={(e) => setInvestment(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="1000" max="100000"
                            value={investment} onChange={(e) => setInvestment(Number(e.target.value))} />

                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Expected Return Rate (p.a)</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">%</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 px-6"
                                    value={returnRate}
                                    onChange={(e) => setReturnRate(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="1" max="30"
                            value={returnRate} onChange={(e) => setReturnRate(Number(e.target.value))} />

                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Time Period</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">Yr</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 px-6"
                                    value={timePeriod}
                                    onChange={(e) => setTimePeriod(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="1" max="50"
                            value={timePeriod} onChange={(e) => setTimePeriod(Number(e.target.value))} />
                        <br/>
                  
                        <div className="bg-gray-100 p-4 rounded-md text-gray-800">
                            <p className="flex justify-between text-sm"><span>Invested Amount</span> <span className="text-md font-semibold">₹{investment.toLocaleString()}</span></p>
                            <p className="flex justify-between text-sm"><span>Est. Returns</span> <span className="text-md font-semibold">₹{estReturns.toLocaleString()}</span></p>
                            <p className="flex justify-between text-sm mb-0"><span>Total Value</span> <span className="text-md font-semibold">₹{totalValue.toLocaleString()}</span></p>
                        </div>
                    </div>

                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        <a
                            href="/home"
                        >
                            <button className="w-full bg-[#005555] text-white py-2 rounded mt-4">
                                INVEST NOW
                            </button>
                        </a>
                    </div>
                </div>

                <div className="bg-white border border-gray-300 rounded-lg">
                    <div className="text-gray-700 text-base font-semibold p-3">Popular Calculators</div>
                    <div className="flex flex-col divide-y divide-gray-300">
                        {[
                             { name: "SIP Calculator", link: "/sip-calculator" },
                             { name: "PPF Calculator", link: "/ppf-calculator" },
                             { name: "EPF Calculator", link: "/epf-calculator" },
                             { name: "Lumpsum Calculator", link: "/lumpsum-calculator" },
                             { name: "FD Calculator", link: "/fd-calculator" },
                             { name: "SWP Calculator", link: "/swp-calculator" },
                             { name: "Mutual Fund Returns Calculator", link: "/mutual-fund-calculator" },
                             { name: "RD Calculator", link: "/rd-calculator" },
                             { name: "SSY Calculator", link: "/ssy-calculator" },
                             { name: "Home Loan Calculator", link: "/home-loan-calculator" },
                        ].map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className="p-3 text-gray-600 text-sm hover:text-[#005555] hover:bg-[#E5E7EB]"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                </div>

                
                <div className="md:col-span-2">
                    <h3 className="text-md font-semibold mt-4">Types of Mutual Fund Investments</h3>
                    <p className="text-gray-700">
                        Investments in Mutual Funds can be broadly classified into two types: Lumpsum and SIP.
                    </p>

                    <ul className="list-disc pl-5 text-gray-700">
                        <li><strong>Lumpsum Investment:</strong> This involves investing a significant sum of money in a mutual fund scheme at one go.</li>
                        <li><strong>SIP (Systematic Investment Plan):</strong> This involves investing smaller amounts at regular intervals, typically monthly.</li>
                    </ul>

                    <p className="text-gray-700 mt-2">
                        Both these mutual fund investment strategies have their own advantages. Lumpsum investments are favored by many investors because they involve fewer variables and have the potential for higher returns.
                        On the other hand, SIP investments help in rupee-cost averaging and reduce the impact of market volatility.
                    </p>

                    <h3 className="text-md font-semibold mt-4">Why Use a Lumpsum Calculator?</h3>
                    <p className="text-gray-700">
                        A mutual fund lumpsum calculator can help investors determine the estimated returns on their investments. Understanding different types of returns is crucial for making informed investment decisions.
                    </p>

                    <h4 className="text-sm font-semibold mt-3">Types of Returns in Lumpsum Investment</h4>
                    <ul className="list-disc pl-5 text-gray-700">
                        <li>Absolute Return</li>
                        <li>Total Return</li>
                        <li>Annualized Return</li>
                        <li>Point-to-Point Return</li>
                        <li>Trailing Return</li>
                        <li>Rolling Return</li>
                    </ul>

                    <p className="text-gray-700 mt-2">
                        Investors must understand these return types in detail to maximize the benefits of their mutual fund investments.
                    </p>

                    <h3 className="text-md font-semibold mt-4">Benefits of Using a Lumpsum Return Calculator</h3>
                    <ul className="list-disc pl-5 text-gray-700">
                        <li>Provides an estimated return for different investment periods (1-year, 3-year, 5-year, or more).</li>
                        <li>Simple and easy to use, even for beginners.</li>
                        <li>Gives a reasonably accurate estimate of potential earnings.</li>
                        <li>Helps investors plan their finances effectively based on projected returns.</li>
                        <li>Enables comparison of different investment strategies for better decision-making.</li>
                        <li>Saves time and effort by automating return calculations.</li>
                    </ul>

                    <p className="text-gray-700 mt-2">
                        While the Lumpsum Calculator provides valuable insights, it is important to remember that mutual fund investments are subject to market risks, and actual returns may vary based on market conditions.
                    </p>

                    <p className="text-gray-700 font-semibold mt-4">Use the Lumpsum Calculator today and make well-informed investment decisions!</p>                    <p className="text-gray-700">
                        A lumpsum investment refers to a one-time investment in a mutual fund scheme. This type of investment is done for a fixed amount, unlike SIP, where you invest a smaller amount regularly. The Lumpsum Calculator helps estimate the returns you can earn on your lump sum investments.
                    </p>
                </div>

            </div>
        </div>
    );
};

export default LUMPSUMCalculator;
