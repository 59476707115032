import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const RDCalculator = () => {
    const navigate = useNavigate();
    const [monthlyInvestment, setMonthlyInvestment] = useState(50000);
    const [interestRate, setInterestRate] = useState(6.5);
    const [timePeriod, setTimePeriod] = useState(3);

    const N = 4; // Quarterly compounding
    const R = interestRate / 100;
    const t = timePeriod;

    const calculateMaturityAmount = () => {
        let maturityAmount = 0;
        for (let i = t * 12; i > 0; i--) {
            maturityAmount += monthlyInvestment * Math.pow(1 + R / N, N * (i / 12));
        }
        return maturityAmount;
    };

    const maturityAmount = calculateMaturityAmount();
    const investedAmount = monthlyInvestment * t * 12;
    const interestEarned = maturityAmount - investedAmount;

    const chartOptions = {
        chart: { type: "pie", options3d: { enabled: true, alpha: 45 } },
        title: { text: "Investment Breakdown", align: "center", style: { color: "#005555", fontSize: "16px" } },
        plotOptions: { pie: { innerSize: "60%", depth: 45, dataLabels: { enabled: false } } },
        legend: { enabled: true, layout: "horizontal", align: "left", verticalAlign: "bottom", itemStyle: { fontSize: "12px" } },
        series: [{
            name: "Amount",
            data: [
                { name: "Invested Amount", y: investedAmount, color: "#EAEAEA" },
                { name: "Interest Earned", y: interestEarned, color: "#005555" }
            ],
            showInLegend: true
        }]
    };

    return (
        <div className="section-properties container pb-12">
            <div className="w-full flex justify-between items-center">
                <p className="font-bold text-primary text-xl cursor-pointer my-4" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} /> RD Calculator
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="md:col-span-2 shadow-sm p-6 bg-white rounded-lg border border-gray-300 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2">
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Monthly Deposit</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">₹</div>
                                <input type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded w-full py-2 px-6" value={monthlyInvestment} onChange={(e) => setMonthlyInvestment(Number(e.target.value))} />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="1000" max="100000" value={monthlyInvestment} onChange={(e) => setMonthlyInvestment(Number(e.target.value))} />
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Interest Rate (p.a)</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">%</div>
                                <input type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded w-full py-2 px-6" value={interestRate} onChange={(e) => setInterestRate(Number(e.target.value))} />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="1" max="10" value={interestRate} onChange={(e) => setInterestRate(Number(e.target.value))} />
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Time Period (Years)</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">Yr</div>
                                <input type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded w-full py-2 px-6" value={timePeriod} onChange={(e) => setTimePeriod(Number(e.target.value))} />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="1" max="10" value={timePeriod} onChange={(e) => setTimePeriod(Number(e.target.value))} />
                        <div className="bg-gray-100 p-4 rounded-md text-gray-800">
                            <p className="flex justify-between text-sm"><span>Invested Amount</span> <span className="text-md font-semibold">₹{investedAmount.toLocaleString()}</span></p>
                            <p className="flex justify-between text-sm"><span>Est. Return</span> <span className="text-md font-semibold">₹{interestEarned.toLocaleString()}</span></p>
                            <p className="flex justify-between text-sm mb-0"><span>Total Value</span> <span className="text-md font-semibold">₹{maturityAmount.toLocaleString()}</span></p>
                        </div>
                    </div>
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        <button className="w-full bg-[#005555] text-white py-2 rounded mt-4">INVEST NOW</button>
                    </div>
                </div>


                <div className="bg-white border border-gray-300 rounded-lg">
                    <div className="text-gray-700 text-base font-semibold p-3">Popular Calculators</div>
                    <div className="flex flex-col divide-y divide-gray-300">
                        {[
                            { name: "SIP Calculator", link: "/sip-calculator" },
                            { name: "PPF Calculator", link: "/ppf-calculator" },
                            { name: "EPF Calculator", link: "/epf-calculator" },
                            { name: "Lumpsum Calculator", link: "/lumpsum-calculator" },
                            { name: "FD Calculator", link: "/fd-calculator" },
                            { name: "SWP Calculator", link: "/swp-calculator" },
                            { name: "Mutual Fund Returns Calculator", link: "/mutual-fund-calculator" },
                            { name: "RD Calculator", link: "/rd-calculator" },
                            { name: "SSY Calculator", link: "/ssy-calculator" },
                            { name: "Home Loan Calculator", link: "/home-loan-calculator" },
                        ].map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className="p-3 text-gray-600 text-sm hover:text-[#005555] hover:bg-[#E5E7EB]"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                </div>


                <div className="md:col-span-2">

                    <p className="text-gray-700">
                        Recurring deposits (RDs) are an investment instrument almost similar to fixed deposits. However, you have to make fixed monthly deposits in RDs, unlike a lump sum amount in FDs. RDs create a habit of regular investment among earning individuals. These also instil discipline when it comes to savings. Recurring deposits are offered by the majority of banks and financial institutions.
                    </p>
                    <p className="text-gray-700">
                        RD returns calculation can be quite complicated for an average investor to figure out accurately every time. This is where an RD calculator can prove to be immensely beneficial.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">How can an RD calculator help you?</h2>
                    <p className="text-gray-700">
                        A recurring deposit, as the name suggests, is a continuing investment. The returns on these deposits can be challenging to track for investors. The interest is compounded quarterly, and there are several variables involved, which makes the calculations multipart.</p>
                    <p className="text-gray-700">
                        An RD deposit calculator eliminates the hassle of computing its returns manually and enables an investor to know the exact amount their deposits will accrue after the relevant period. </p>
                    <p className="text-gray-700">
                        The only consideration that the investor has to do manually is the TDS deduction. As per new RBI norms, RDs are also liable for TDS deduction; however, there is no uniformity in its implementation across financial institutions, which is why RD calculators don’t take it into account.</p>
                    <p className="text-gray-700">
                        Apart from that small caveat, an RD amount calculator offers an investor with the following advantages:</p>
                    <ol className="list-disc">
                        <li>The calculator enables investors to plan their future finances with greater clarity by providing them with the exact amount their investment will accrue.</li>
                        <li>It’s convenient to use and saves a lot of time for the investors, which they can otherwise use productively.</li>
                        <li>The accuracy of these calculators can never be in question. Accurate estimates are pivotal for prudent financial planning. </li>
                    </ol>

                </div>

            </div>
        </div>
    );
};

export default RDCalculator;