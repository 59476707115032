import toast from "toastr";
import { useState } from "react";
import Loader from "../../components/Loader";

const NotFoundPage = () => {
  toast.options = { preventDuplicates: true };
  const [loader, setLoader] = useState(false);

  return (
    <div style={{marginTop: '-140px'}}>
      {loader && <Loader />}
      <div
        className="hero page-inner overlay"
        style={{
          backgroundImage: `url(${"/images/hero_bg_1.jpg"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-9 text-center">
              <h1 className="heading mt-52">
                404 - Page Not Found
              </h1>
              <h2 className="MuiTypography-root MuiTypography-h5 css-el0wq6">
                The page you’re looking for doesn’t exist or has been moved.
                Please check the URL or return to the homepage.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
