import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

const PPFCalculator = () => {
    const navigate = useNavigate();
    const [investment, setInvestment] = useState(12000);
    const [returnRate, setReturnRate] = useState(7.1);
    const [timePeriod, setTimePeriod] = useState(15);

    const investedAmount = investment * timePeriod;
    const totalValue = investment * ((Math.pow(1 + returnRate / 100, timePeriod) - 1) / (returnRate / 100)) * (1 + returnRate / 100);
    const estReturns = totalValue - investedAmount;

    const chartOptions = {
        chart: {
            type: "pie",
            options3d: {
                enabled: true,
                alpha: 45,
            },
        },
        title: {
            text: "Investment Breakdown",
            align: "center",
            style: { color: "#005555", fontSize: "14px" }
        },
        plotOptions: {
            pie: {
                innerSize: "60%",
                depth: 45,
                dataLabels: {
                    enabled: false,
                },
            },
        },
        legend: {
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "bottom",
            itemStyle: {
                fontSize: "12px"
            }
        },
        series: [
            {
                name: "Amount",
                data: [
                    { name: "Invested Amount", y: investedAmount, color: "#EAEAEA" },
                    { name: "Total Interest", y: estReturns, color: "#005555" },
                ],
                showInLegend: true,
            },
        ],
    };

    return (
        <div className="section-properties container pb-12">
            {/* Top Navigation Section */}
            <div className="w-full flex justify-between items-center">
                <p
                    className="font-bold text-primary text-xl cursor-pointer my-4"
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} /> PPF Calculator
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

                <div className="md:col-span-2 p-6 bg-white shadow-sm rounded-lg border border-gray-300 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2 md:mt-6">
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700 w-40">Yearly investment</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">₹</div>
                                <input type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" value={investment} onChange={(e) => setInvestment(Number(e.target.value))} />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="5000" max="150000" value={investment} onChange={(e) => setInvestment(Number(e.target.value))} />
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700 w-40">Time period (in years)</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">Yr</div>
                                <input type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" value={timePeriod} onChange={(e) => setTimePeriod(Number(e.target.value))} />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="15" max="50" value={timePeriod} onChange={(e) => setTimePeriod(Number(e.target.value))} />
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700 w-40">Rate of interest</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">%</div>
                                <input type="number" className="bg-gray-50 border border-gray-300 text-[#ff0000] text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" value={returnRate} readOnly />
                            </div>
                        </div>
                        <br/>
                        <div className="bg-gray-100 p-4 rounded-md text-gray-800">
                            <p className="flex justify-between text-sm"><span>Invested amount</span> <span className="text-md font-semibold">₹{investedAmount.toLocaleString()}</span></p>
                            <p className="flex justify-between text-sm"><span>Est. returns</span> <span className="text-md font-semibold">₹{estReturns.toLocaleString()}</span></p>
                            <p className="flex justify-between text-sm mb-0"><span>Total value</span> <span className="text-md font-semibold">₹{totalValue.toLocaleString()}</span></p>
                        </div>
                    </div>
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        <a
                            href="/home"
                        >
                            <button className="w-full bg-[#005555] text-white py-2 rounded mt-4">
                                SAVE TAX
                            </button>
                        </a>
                    </div>
                </div>

                <div className="bg-white border border-gray-300 rounded-lg">
                    <div className="text-gray-700 text-base font-semibold p-3">Popular Calculators</div>
                    <div className="flex flex-col divide-y divide-gray-300">
                        {[
                             { name: "SIP Calculator", link: "/sip-calculator" },
                             { name: "PPF Calculator", link: "/ppf-calculator" },
                             { name: "EPF Calculator", link: "/epf-calculator" },
                             { name: "Lumpsum Calculator", link: "/lumpsum-calculator" },
                             { name: "FD Calculator", link: "/fd-calculator" },
                             { name: "SWP Calculator", link: "/swp-calculator" },
                             { name: "Mutual Fund Returns Calculator", link: "/mutual-fund-calculator" },
                             { name: "RD Calculator", link: "/rd-calculator" },
                             { name: "SSY Calculator", link: "/ssy-calculator" },
                             { name: "Home Loan Calculator", link: "/home-loan-calculator" },
                        ].map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className="p-3 text-gray-600 text-sm hover:text-[#005555] hover:bg-[#E5E7EB]"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                </div>

                
                <div className="md:col-span-2">
                    <p className="text-gray-700">
                        The first step towards wealth management is accumulating savings. You will find a lot of options for savings accounts; however, look for the ones that guarantee substantial returns risk-free. PPF accounts are one of the most common features which come into the picture. PPF account refers to Public Provident fund account and is meant to invest your valuable capital.
                    </p>
                    <p className="text-gray-700">
                        If you are a new employee or a responsible parent who wishes to save for the future, then PPF is ideal for you. Calculating the interest rates and returns on your PPF account turns a bit difficult. To make these difficult calculations easy, PPF account calculator can be used.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">How can a PPF calculator help you?</h2>
                    <p className="text-gray-700">
                        This financial tool allows one to resolve their queries related to Public Provident Fund account. There are certain specifications that are to be abided by while calculating maturity amount after a certain point of time. It keeps a track on the growth of your capital. Those who already have a PPF savings account know that interest rates change on monthly basis.
                    </p>
                    <p className="text-gray-700">
                        Nowadays, it is easier to keep a check on changing rates. However, with the discovery of public provident fund calculator, account holders find it easier to find out monthly changes made in interest. In the market, you may find lot of user-friendly PPF calculators and for choosing trustworthy ones, Groww is simply the option.
                    </p>

                </div>

            </div>
        </div>
    );
};

export default PPFCalculator;
