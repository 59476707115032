import { useNavigate, useLocation } from "react-router-dom";
import { logoutApi } from "../../api";
import toast from "toastr";
import { useState, useEffect } from "react";
import Loader from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCoins,
  faEnvelope,
  faHome,
  faInfoCircle,
  faUser,
  faCalculator,
  faRssSquare,
  faMoneyBillTransfer,
  faPieChart,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import LogoImg from "../../images/Logo.png";

const Header = ({ pageName }) => {
  toast.options = { preventDuplicates: true };
  const [loader, setLoader] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userName, setUserName] = useState('userInfo');

  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = () => {
    return !!localStorage.getItem("token");
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('userInfo'));
    if (storedUser) {
      setUserName(storedUser.firstName);
    }
  }, []);

  const handleLogout = async () => {
    setIsMobileMenuOpen(false);
    if (isAuthenticated()) {
      try {
        setLoader(true);
        const res = await logoutApi();
        if (res?.status === 200) {
          toast.success(res?.data?.message);
        }
        setLoader(false);
        localStorage.clear();
        navigate("/home");

      } catch (err) {
        setLoader(false);
        localStorage.clear();
        navigate("/home");
      }
    } else {
      navigate("/login");
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      {loader && <Loader />}
      <div className={`drawer ${isMobileMenuOpen ? "open" : ""}`}>
        <img src={LogoImg} alt="logo" width={100} />
        {/* <p
          onClick={toggleMobileMenu}
          className="text-center text-[24px] text-[#005555] -mt-4 font-bold w-full"
        >
          Share Bric
        </p> */}
        <a
          href="/home"
          onClick={toggleMobileMenu}
          className={location.pathname === "/home" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faHome} className="mr-4" />
          Home
        </a>
        <a
          href="/properties"
          onClick={toggleMobileMenu}
          className={location.pathname === "/properties" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faBuilding} className="mr-4" />
          Properties
        </a>
        <a
          href="/about"
          onClick={toggleMobileMenu}
          className={location.pathname === "/about" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faInfoCircle} className="mr-4" />
          About
        </a>
        <a
          href="/how-it-works"
          onClick={toggleMobileMenu}
          className={location.pathname === "/how-it-works" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faCoins} className="mr-4" />
          How does it works
        </a>

        <a
          href="/contact-us"
          onClick={toggleMobileMenu}
          className={location.pathname === "/contact-us" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faEnvelope} className="mr-4" />
          Contact Us
        </a>

        <a
          href="/blogs"
          onClick={toggleMobileMenu}
          className={location.pathname === "/blogs" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faRssSquare} className="mr-4" />
          Blogs
        </a>

        <a
          href="/calculators"
          onClick={toggleMobileMenu}
          className={location.pathname === "/calculators" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faCalculator} className="mr-4" />
          Calculators
        </a>

        {isAuthenticated() && (
          <a
            href="/purchased-properties"
            onClick={toggleMobileMenu}
            className={
              location.pathname === "/purchased-properties" ? "active" : ""
            }
          >
            <FontAwesomeIcon icon={faPieChart} className="mr-4" />
            My Account
          </a>
        )}
        {isAuthenticated() && (
          <a
            href="/withdraw-properties"
            onClick={toggleMobileMenu}
            className={
              location.pathname === "/withdraw-properties" ? "active" : ""
            }
          >
            <FontAwesomeIcon icon={faMoneyBillTransfer} className="mr-4" />
            Withdraw
          </a>
        )}
        {isAuthenticated() && (
          <a
            href="/profile"
            onClick={toggleMobileMenu}
            className={location.pathname === "/profile" ? "active" : ""}
          >
            <FontAwesomeIcon icon={faUser} className="mr-4" />
            Profile
          </a>
        )}
        <div className="logout-container px-2 ml-2">
          <p
            onClick={handleLogout}
            className="logout-btn text-[15px] text-[#005555] border border-[#005555] hover:text-white cursor-pointer"
          >
            {isAuthenticated() ? "Logout" : "Login"}
          </p>
        </div>
      </div>

      <nav className="site-nav">
        <div className="container">
          <div className="menu-bg-wrap">
            <div className="site-navigation ">
              <a href="/home" className="logo m-0 float-start">
                <img src={LogoImg} alt="logo" width={100} />
              </a>

              <ul className="js-clone-nav d-none d-lg-inline-block text-start site-menu float-end">
                <li className={location.pathname === "/home" ? "active " : ""}>
                  <a href="/home">Home</a>
                </li>
                <li
                  className={
                    location.pathname === "/properties" ? "active" : ""
                  }
                >
                  <a href="/properties">Properties</a>
                </li>
                <li className={location.pathname === "/about" ? "active" : ""}>
                  <a href="/about">About</a>
                </li>
                <li
                  className={
                    location.pathname === "/how-it-works" ? "active" : ""
                  }
                >
                  <a href="/how-it-works">How does it works</a>
                </li>

                <li
                  className={
                    location.pathname === "/contact-us" ? "active" : ""
                  }
                >
                  <a href="/contact-us">Contact Us</a>
                </li>

                <li
                  className={`dropdown ${location.pathname === "/profile" ||
                    location.pathname === "/purchased-properties" ||
                    location.pathname === "/withdraw-properties"
                    ? "active"
                    : ""
                    }`}
                >
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    More
                  </a>

                  <ul className="dropdown-menu">

                    <li
                      className={
                        location.pathname === "/blogs"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href="/blogs"
                        className="text-[14px] whitespace-nowrap"
                      >
                        <FontAwesomeIcon icon={faRssSquare} className="mr-3" />
                        Blogs
                      </a>
                    </li>
                    <li
                      className={
                        location.pathname === "/calculators"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href="/calculators"
                        className="text-[14px] whitespace-nowrap"
                      ><FontAwesomeIcon icon={faCalculator} className="mr-3" />
                        Calculators
                      </a>
                    </li>

                  </ul>
                </li>

                {isAuthenticated() && (
                  <li
                    className={`dropdown ${location.pathname === "/profile" ||
                      location.pathname === "/purchased-properties" ||
                      location.pathname === "/withdraw-properties"
                      ? "active"
                      : ""
                      }`}
                  >
                    <a
                      href="/profile"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      {userName || "Profile"}
                    </a>

                    <ul className="dropdown-menu">
                      <li
                        className={
                          location.pathname === "/profile" ? "active" : ""
                        }
                      >
                        <a href="/profile" className="text-[14px]">
                          <FontAwesomeIcon icon={faUser} className="mr-4" />
                          Profile
                        </a>
                      </li>
                      <li
                        className={
                          location.pathname === "/purchased-properties"
                            ? "active"
                            : ""
                        }
                      >
                        <a
                          href="/purchased-properties"
                          className="text-[14px] whitespace-nowrap"
                        >
                          <FontAwesomeIcon icon={faPieChart} className="mr-4" />
                          My Account
                        </a>
                      </li>
                      <li
                        className={
                          location.pathname === "/withdraw-properties"
                            ? "active"
                            : ""
                        }
                      >
                        <a
                          href="/withdraw-properties"
                          className="text-[14px] whitespace-nowrap"
                        >
                          <FontAwesomeIcon icon={faMoneyBillTransfer} className="mr-4" />
                          Withdraw
                        </a>
                      </li>
                      {isAuthenticated() && (
                        <li>
                          <p
                            onClick={handleLogout}
                            className="text-[14px] whitespace-nowrap cursor-pointer mb-0 text-red-600"
                          ><FontAwesomeIcon icon={faSignOutAlt} className="mr-4" />
                            Logout
                          </p>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {!isAuthenticated() && (
                  <li>
                    <p
                      onClick={handleLogout}
                      className="text-[#FFFFFF80] hover:text-white cursor-pointer"
                    >
                      {!isAuthenticated() && "Login"}
                    </p>
                  </li>
                )}
              </ul>

              <div
                className="burger light me-auto float-end mt-1 site-menu-toggle js-menu-toggle d-inline-block d-lg-none"
                data-toggle="collapse"
                data-target="#main-navbar"
                onClick={toggleMobileMenu}
              >
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {(window.location.pathname === "/" ||
        window.location.pathname === "/home") ? (
        <div
          className="hero page-inner overlay"
          style={{
            backgroundImage: `url(${"/images/hero_bg_1.jpg"})`,
            backgroundSize: "cover", // Ensures the image covers the entire div
            backgroundPosition: "center", // Centers the image
            width: "100%", // Ensures the div takes full width
            height: "90vh",
          }}
        >

          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-9 text-center">
                <h1 className="heading mt-52">
                  Easily invest in real estate
                </h1>
                <h2 className="MuiTypography-root MuiTypography-h5 css-el0wq6">
                  Maximize your wealth — unlock the benefits of real estate
                  investing with rental income and appreciation.
                </h2>
                {/* <form
                    action="#"
                    className="narrow-w form-search d-flex align-items-stretch mb-3"
                  >
                    <input
                      type="text"
                      className="form-control px-4"
                      placeholder="Your ZIP code or City. e.g. New York"
                    />
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </form> */}
              </div>
            </div>
          </div>

        </div>
      ) :
        (
          <div className="mt-32"></div>
        )}
    </div>
  );
};

export default Header;
