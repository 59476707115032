import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const SSYCalculator = () => {
    const navigate = useNavigate();
    const [yearlyInvestment, setYearlyInvestment] = useState(10000);
    const [girlAge, setGirlAge] = useState(5);
    const [startYear, setStartYear] = useState(2021);
    const interestRate = 8.2 / 100;
    const maturityYear = startYear + 21;
    const maxContributionYears = Math.min(14, maturityYear - startYear);

    const totalInvestment = (yearlyInvestment * maxContributionYears + yearlyInvestment);
    let maturityValue = 0;

    for (let i = 0; i < maxContributionYears; i++) {
        let yearsRemaining = maturityYear - (startYear + i);
        let futureValue = yearlyInvestment * Math.pow((1 + interestRate), yearsRemaining);
        maturityValue += futureValue;
    }
    const totalInterest = maturityValue - totalInvestment;

    const chartOptions = {
        chart: {
            type: "pie",
            options3d: {
                enabled: true,
                alpha: 45,
            },
        },
        title: {
            text: "Investment Breakdown",
            align: "center",
            style: { color: "#005555", fontSize: "16px" }
        },
        plotOptions: {
            pie: {
                innerSize: "60%",
                depth: 45,
                dataLabels: {
                    enabled: false,
                },
            },
        },
        legend: {
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "bottom",
            itemStyle: {
                fontSize: "12px"
            }
        },
        series: [
            {
                name: "Amount",
                data: [
                    { name: "Total Investment", y: totalInvestment, color: "#EAEAEA" },
                    { name: "Total Interest", y: totalInterest, color: "#005555" },
                ],
                showInLegend: true,
            },
        ],
    };

    return (
        <div className="section-properties container pb-12">
            {/* Top Navigation Section */}
            <div className="w-full flex justify-between items-center">
                <p
                    className="font-bold text-primary text-xl cursor-pointer my-4"
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} /> Sukanya Samriddhi Yojana Calculator
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

                <div className="md:col-span-2 shadow-sm p-6 bg-white rounded-lg border border-gray-300 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2">

                        <div className="my-3 flex items-center justify-between">
                            <label className="text-gray-700">Yearly investment</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">₹</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6"
                                    value={yearlyInvestment}
                                    onChange={(e) => setYearlyInvestment(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input
                            type="range"
                            min="250"
                            max="150000"
                            step="250"
                            value={yearlyInvestment}
                            onChange={(e) => setYearlyInvestment(Number(e.target.value))}
                            className="w-full mb-4"
                        />

                        <div className="my-3 flex items-center justify-between">
                            <label className="text-gray-700">Girl's age</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">Yr</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6"
                                    value={girlAge}
                                    onChange={(e) => setGirlAge(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input
                            type="range"
                            min="1"
                            max="10"
                            step="1"
                            value={girlAge}
                            onChange={(e) => setGirlAge(Number(e.target.value))}
                            className="w-full mb-4"
                        />

                        <div className="my-3 flex items-center justify-between">
                            <label className="text-gray-700">Start period</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none"></div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6"
                                    value={startYear}
                                    onChange={(e) => setStartYear(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input
                            type="range"
                            min="2018"
                            max="2030"
                            step="1"
                            value={startYear}
                            onChange={(e) => setStartYear(Number(e.target.value))}
                            className="w-full mb-4"
                        />
                        <br />
                        <br />
                        <p className="text-xs">Note* Latest SSY Rate = 8.2%</p>
                        <div className="bg-gray-100 p-4 rounded-md text-gray-800">
                            <p className="flex justify-between text-sm"><span>Total investment</span> <span className="text-md font-semibold">₹{totalInvestment.toLocaleString()}</span></p>
                            <p className="flex justify-between text-sm"><span>Total interest</span> <span className="text-md font-semibold">₹{totalInterest.toLocaleString()}</span></p>
                            <p className="flex justify-between text-sm"><span>Maturity year</span> <span className="text-md font-semibold">{maturityYear}</span></p>
                            <p className="flex justify-between text-sm mb-0"><span>Maturity value</span> <span className="text-md font-semibold">₹{maturityValue.toLocaleString()}</span></p>
                        </div>
                    </div>
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        <a
                            href="/home"
                        >
                            <button className="w-full bg-[#005555] text-white py-2 rounded mt-4">
                                INVEST NOW
                            </button>
                        </a>
                    </div>
                </div>

                <div className="bg-white border border-gray-300 rounded-lg">
                    <div className="text-gray-700 text-base font-semibold p-3">Popular Calculators</div>
                    <div className="flex flex-col divide-y divide-gray-300">
                        {[
                            { name: "SIP Calculator", link: "/sip-calculator" },
                            { name: "PPF Calculator", link: "/ppf-calculator" },
                            { name: "EPF Calculator", link: "/epf-calculator" },
                            { name: "Lumpsum Calculator", link: "/lumpsum-calculator" },
                            { name: "FD Calculator", link: "/fd-calculator" },
                            { name: "SWP Calculator", link: "/swp-calculator" },
                            { name: "Mutual Fund Returns Calculator", link: "/mutual-fund-calculator" },
                            { name: "RD Calculator", link: "/rd-calculator" },
                            { name: "SSY Calculator", link: "/ssy-calculator" },
                            { name: "Home Loan Calculator", link: "/home-loan-calculator" },
                        ].map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className="p-3 text-gray-600 text-sm hover:text-[#005555] hover:bg-[#E5E7EB]"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                </div>

                <div className="md:col-span-2">


                    <h2 className="text-lg font-semibold mb-2">SSY Calculator – Sukanya Samriddhi Yojana Calculator</h2>
                    <p className="text-gray-700">
                        Sukanya Samriddhi Yojana (SSY) is a savings scheme launched back in 2015 as part of the Government initiative Beti Bachao, Beti Padhao campaign. This scheme enables guardians to open a savings account for their girl child with an authorised commercial bank or India Post branch.
                    </p>

                    <p className="text-gray-700">
                        SSY accounts offer an 8.2% rate of interest. A Sukanya Samriddhi Yojana calculator can help you determine the returns you receive as per the invested amount and tenure.
                    </p>

                    <h2 className="text-lg font-semibold mb-2">Who Can Use This Calculator? </h2>
                    <p className="text-gray-700">
                        The first step to take benefit of the SSY calculator is to check whether the eligibility criteria of the scheme is fulfilled. SSY account can be opened by legal guardians of the girl child provided the following conditions are met:-</p>

                    <ul className="list-disc">
                        <li>The girl must be an Indian resident</li>
                        <li>The girl shouldn’t be more than 10 years of age</li>
                        <li>Upto two accounts can be opened in a family with two girl children.</li>
                    </ul>
                    <p className="text-gray-700">
                        Additionally, the legal guardians will also need to submit the following documents to be able to start the deposits in the scheme:- </p>
                    
                    <ul className="list-disc">
                        <li>Duly filled scheme opening document which covers the basic personal details of the account holder and the girl child for whom the account is being opened.</li>
                        <li>Birth certificate of the girl child.</li>
                        <li>Depositor’s identification documents as well as a valid address proof.</li>
                        <li>Medical certificate in the case of the birth of multiple children under a single birth order.</li>
                        <li>Additional documents requested by the concerned authority.</li>
                    </ul>
                    <p className="text-gray-700">Individuals who meet the aforementioned pre-requisites as well as have the supporting documents for the same are eligible for the scheme and hence can go ahead and use the&nbsp;SSY calculator online.</p>


                </div>
            </div>
        </div>
    );
};

export default SSYCalculator;
