import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const MutualFundCalculator = () => {
    const navigate = useNavigate();
    const [investment, setInvestment] = useState(500);
    const [returnRate, setReturnRate] = useState(1);
    const [timePeriod, setTimePeriod] = useState(1);
    const [mode, setMode] = useState("SIP");

    const r = returnRate / 100;
    const n = 1;

    const investedAmount = mode === "SIP" ? investment * timePeriod : investment;

    const totalValue = mode === "SIP"
        ? investment * ((Math.pow(1 + r / n, n*timePeriod) - 1) / (r / n)) * (1 + r / n)
        : investment * Math.pow(1 + r / n, n*timePeriod);

    const estReturns = totalValue - investedAmount;


    const chartOptions = {
        chart: {
            type: "pie",
            options3d: { enabled: true, alpha: 45 },
        },
        title: {
            text: "Investment Breakdown",
            align: "center",
            style: { color: "#005555", fontSize: "16px" }
        },
        plotOptions: {
            pie: {
                innerSize: "60%",
                depth: 45,
                dataLabels: { enabled: false },
            },
        },
        legend: {
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "bottom",
            itemStyle: { fontSize: "12px" }
        },
        series: [
            {
                name: "Amount",
                data: [
                    { name: "Invested Amount", y: investedAmount, color: "#EAEAEA" },
                    { name: "Est. Returns", y: estReturns, color: "#005555" },
                ],
                showInLegend: true,
            },
        ],
    };

    return (
        <div className="section-properties container pb-12">
            <div className="w-full flex justify-between items-center">
                <p
                    className="font-bold text-primary text-xl cursor-pointer my-4"
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} /> MutualFund Calculator
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="md:col-span-2 shadow-sm p-6 bg-white rounded-lg border border-gray-300 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2">
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">{"Total investment"}</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">₹</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6"
                                    value={investment}
                                    onChange={(e) => setInvestment(Number(e.target.value))}
                                />
                            </div>
                        </div>

                        <input
                            type="range"
                            className="w-full mb-4"
                            min="500"
                            max="100000"
                            value={investment}
                            onChange={(e) => setInvestment(Number(e.target.value))}
                        />

                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Expected return rate (p.a)</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">%</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6"
                                    value={returnRate}
                                    onChange={(e) => setReturnRate(Number(e.target.value))}
                                />
                            </div>
                        </div>

                        <input
                            type="range"
                            className="w-full mb-4"
                            min="1"
                            max="30"
                            value={returnRate}
                            onChange={(e) => setReturnRate(Number(e.target.value))}
                        />

                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Time period</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">Yr</div>
                                <input
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6"
                                    value={timePeriod}
                                    onChange={(e) => setTimePeriod(Number(e.target.value))}
                                />
                            </div>
                        </div>

                        <input
                            type="range"
                            className="w-full mb-4"
                            min="1"
                            max="50"
                            value={timePeriod}
                            onChange={(e) => setTimePeriod(Number(e.target.value))}
                        />

                        <div className="bg-gray-100 p-4 rounded-md text-gray-800">
                            <p className="flex justify-between text-sm">
                                <span>Invested amount</span>
                                <span className="text-md font-semibold">₹{investedAmount.toLocaleString()}</span>
                            </p>
                            <p className="flex justify-between text-sm">
                                <span>Est. returns</span>
                                <span className="text-md font-semibold">₹{estReturns.toLocaleString()}</span>
                            </p>
                            <p className="flex justify-between text-sm">
                                <span>Total value</span>
                                <span className="text-md font-semibold">₹{totalValue.toLocaleString()}</span>
                            </p>
                        </div>
                    </div>

                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        <a href="/home">
                            <button className="w-full bg-[#005555] text-white py-2 rounded mt-4">
                                INVEST NOW
                            </button>
                        </a>
                    </div>
                </div>

                <div className="bg-white border border-gray-300 rounded-lg">
                    <div className="text-gray-700 text-base font-semibold p-3">Popular Calculators</div>
                    <div className="flex flex-col divide-y divide-gray-300">
                        {[
                             { name: "SIP Calculator", link: "/sip-calculator" },
                             { name: "PPF Calculator", link: "/ppf-calculator" },
                             { name: "EPF Calculator", link: "/epf-calculator" },
                             { name: "Lumpsum Calculator", link: "/lumpsum-calculator" },
                             { name: "FD Calculator", link: "/fd-calculator" },
                             { name: "SWP Calculator", link: "/swp-calculator" },
                             { name: "Mutual Fund Returns Calculator", link: "/mutual-fund-calculator" },
                             { name: "RD Calculator", link: "/rd-calculator" },
                             { name: "SSY Calculator", link: "/ssy-calculator" },
                             { name: "Home Loan Calculator", link: "/home-loan-calculator" },
                        ].map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className="p-3 text-gray-600 text-sm hover:text-[#005555] hover:bg-[#E5E7EB]"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                </div>
                

                <div className="md:col-span-2">
                    <h2 className="text-lg font-semibold mb-2">Mutual Fund Return Calculator – Estimate Your Returns</h2>
                    <p className="text-gray-700">
                        Mutual funds are one of the most popular avenues of investment in the Indian context. As of June 2019, the average assets under management (AuM) of the entire MF industry stood at a staggering Rs. 24.25 trillion, an over four-fold increase from Rs. 5.83 trillion in 2009.
                    </p>
                    <p className="text-gray-700">
                        Though mutual fund investments are subject to market risks, the returns can be estimated reasonably accurately using tools like the free mutual fund return calculator available on platforms like Groww. This tool helps investors arrive at an estimate of their expected returns from mutual fund investments.
                    </p>
                    <h3 className="text-lg font-semibold mb-2">How Can a Mutual Fund Return Calculator Online Help You?</h3>
                    <p className="text-gray-700">
                        There are various types of mutual fund returns that an investor should be familiar with. These include absolute return, annualised return, total return, trailing return, point to point return, and rolling return.
                    </p>
                    <p className="text-gray-700">
                        It can be somewhat confusing for a prospective investor to keep track of all these factors, which is where the mutual fund return calculator can be immensely helpful. This online tool helps simplify the calculations and provides an estimate of the returns for different time periods—such as 1 year, 3 years, and 5 years—giving investors a clearer idea of potential outcomes.
                    </p>
                    <p className="text-gray-700">
                        The calculator can also help with future financial planning by allowing you to adjust inputs such as the investment amount, return rate, and investment duration to project future returns. Best of all, it’s simple to use and accessible even to those who are new to investing. You don’t need to be a financial expert to navigate it.
                    </p>
                    <h3 className="text-lg font-semibold mb-2">How Does a Mutual Fund Total Return Calculator Work?</h3>
                    <p className="text-gray-700">
                        A mutual fund calculator is a practical financial tool that enables investors to calculate the returns yielded by investing in mutual funds. There are two primary ways to invest in mutual funds—either through a one-time lump sum investment or via a Systematic Investment Plan (SIP).
                    </p>
                    <p className="text-gray-700">
                        In a SIP, an individual invests a small amount every month into designated schemes. The Net Asset Value (NAV) of the fund changes each month, meaning the same amount of money can purchase a different number of units in different months. For example, if the NAV of a mutual fund increases from Rs. 10 to Rs. 20, the same investment amount of Rs. 1000 will purchase fewer units in the second month compared to the first month.
                    </p>
                    <p className="text-gray-700">
                        The SIP calculator predicts the returns on your SIP based on specific parameters such as the amount invested, the duration of investment, and the expected rate of return. You simply need to input these details into the calculator, and it will provide the results in seconds.
                    </p>

                    <h2 className="text-lg font-semibold mb-2">SIP Calculator – Systematic Investment Plan Calculator</h2>
                    <p className="text-gray-700">
                        Prospective investors can often confuse SIPs with mutual funds. However, SIPs are simply a method of investing in mutual funds, with the other method being lump sum investment. A SIP calculator is a tool that helps you determine the returns you can expect from investing in mutual funds via SIP.
                    </p>
                    <p className="text-gray-700">
                        Systematic Investment Plans (SIPs) involve investing a fixed sum of money in mutual funds at regular intervals—monthly, quarterly, or weekly. SIPs are becoming increasingly popular, particularly among millennials, because they offer a disciplined approach to investing and help investors take advantage of market fluctuations over time.
                    </p>
                    <h3 className="text-lg font-semibold mb-2">What is a SIP Calculator?</h3>
                    <p className="text-gray-700">
                        A SIP calculator is a straightforward tool that helps you estimate the potential returns on your mutual fund investments made through SIP. This tool helps you forecast the future value of your SIP investments, based on your expected rate of return and investment tenure.
                    </p>
                    <p className="text-gray-700">
                        While the SIP calculator gives you an estimate of the returns, the actual returns offered by a mutual fund scheme can vary due to factors such as market performance, fund management strategies, and other external conditions. The calculator does not account for exit load or expense ratio, if any.
                    </p>
                    <p className="text-gray-700">
                        The calculator will provide an estimate of the wealth gained and the expected returns for your monthly SIP investment. This gives you a rough idea of the maturity amount for your SIP, based on the projected annual return rate.
                    </p>
                </div>


            </div>
        </div>
    );
};

export default MutualFundCalculator;
